import React from 'react'
import TrustBox from './TrustBox'
import PropTypes from 'prop-types'

const TrustPilotReviews = ({
  reviews,
  itemClassname,
  trustBoxClassName,
  slickSliderSettings,
}) => {
  if (!reviews?.length) {
    return null
  }

  return (
    <TrustBox
      reviews={reviews}
      itemClassname={itemClassname}
      trustBoxClassName={trustBoxClassName}
      slickSliderSettings={slickSliderSettings}
    />
  )
}

TrustPilotReviews.propTypes = {
  reviews: PropTypes.array.isRequired,
  itemClassname: PropTypes.string,
  trustBoxClassName: PropTypes.string,
  slickSliderSettings: PropTypes.object,
}
export default TrustPilotReviews
