import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import FullPageModal from './FullPageModal'
import SvgIcon from '../SvgIcon'
import classNames from 'classnames'

function Modal({
  children,
  onClose,
  open,
  className,
  bodyClassName,
  hasDistanceFromTop,
  title,
  hiddeCloseButton = false,
  closeButtonClassNames = '',
}) {
  const rootRef = useRef()
  // todo: replace with useClickOutside hook
  const handleOutsideClick = useCallback(
    (e) => {
      if (e.target === rootRef.current) {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick)

    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [handleOutsideClick, open])

  return createPortal(
    <CSSTransition
      in={open}
      timeout={500}
      classNames={{
        enter: 'opacity-0',
        enterActive: 'opacity-100 duration-500',
        exitActive: 'opacity-0 duration-500',
      }}
      unmountOnExit
      mountOnEnter
    >
      <div
        data-testid="modal"
        ref={rootRef}
        className={`fixed ${
          hasDistanceFromTop
            ? 'inset-x-0 bottom-0 top-16 lg:inset-0'
            : 'inset-0'
        } z-50 flex items-center justify-center bg-gray-410 p-5`}
        id="modal"
      >
        <div className={`relative rounded-lg bg-white ${className}`}>
          {!hiddeCloseButton && (
            <button
              type="button"
              className={classNames(
                'absolute right-0 top-0 z-50 px-5 text-2xl',
                closeButtonClassNames
              )}
              data-testid="close button"
              onClick={onClose}
              id="close-button"
            >
              <SvgIcon name="closeModal" />
            </button>
          )}
          <div
            className={classNames(
              'max-h-[80vh] lg:overflow-y-auto',
              bodyClassName
            )}
          >
            {title ? (
              <h2 className="text-md font-semibold leading-6 text-blue-700">
                {title}
              </h2>
            ) : null}
            {children}
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.body
  )
}

Modal.defaultProps = {
  open: false,
  hasDistanceFromTop: false,
}
Modal.propTypes = {
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  open: PropTypes.bool,
  bodyClassName: PropTypes.string,
  hasDistanceFromTop: PropTypes.bool,
  hiddeCloseButton: PropTypes.bool,
  closeButtonClassNames: PropTypes.string,
}

export default Modal
export { FullPageModal, Modal }
