import React from 'react'
import axios from 'axios'
import Layout from '../src/components/layouts'
import TestimonialsPage from '../src/components/pages/BrandPages/Testimonials'
import logger from '../src/common/logger'
// import BannerRepository from '../src/repositories/BannerRepository'
// import dynamic from 'next/dynamic'
// import useDeviceDetect from '../src/common/hooks/useDeviceDetect'
// import { BannerPositions } from '../src/components/shared/Banner'
// import Section from '../src/components/shared/BrandPages/Section'

// const Banner = dynamic(() => import('../src/components/shared/Banner'))

export async function getServerSideProps() {
  const { TRUSTPILOT_API_KEY, TRUSTPILOT_BUSINESS_ID } = process.env
  let reviewsRes = {}
  // let banner = {}

  try {
    reviewsRes = await axios.get(
      `https://api.trustpilot.com/v1/business-units/${TRUSTPILOT_BUSINESS_ID}/reviews`,
      {
        params: {
          apikey: TRUSTPILOT_API_KEY,
          stars: '4,5',
          tagValue: 'Compliance-approved',
        },
      }
    )
    // banner = await BannerRepository.getInstance().getByPageSpecified(
    //   'Testimonials'
    // )
  } catch (error) {
    logger.error('testimonials page: failed to get trustpilot data', { error })
  }

  return {
    props: {
      reviews: reviewsRes.data?.reviews || [],
      // banner: banner || {},
    },
  }
}

const Testimonials = (pageProps) => {
  const { reviews } = pageProps
  // const { isDesktop } = useDeviceDetect()

  // const rightBanner = useMemo(() => {
  //   return banner?.position === BannerPositions.RightSide && isDesktop
  // }, [banner, isDesktop])

  return (
    <Layout
      isBrandPage={true}
      footerClassName="mb-5 lg:mb-0"
      dashboardClass="bg-blue-300 text-white"
    >
      {/*<div className={`${rightBanner ? 'grid grid-cols-12' : ''}`}>*/}
      {/*  <div className={`${rightBanner ? 'show col-span-9' : ''}`}>*/}
      <TestimonialsPage data={{ reviews }} />
      {/*</div>*/}
      {/*<div className={`${rightBanner ? 'show col-span-3 mx-11' : 'hidden'}`}>*/}
      {/*  <Section>*/}
      {/*    <Banner {...banner} align="vertical" />*/}
      {/*  </Section>*/}
      {/*</div>*/}
      {/*</div>*/}
    </Layout>
  )
}

export default Testimonials
